import * as firestore from 'firebase/firestore';

export interface Campaign {
  code: string;
  title: boolean;
  note: string;
  description: string;
  startAt: firestore.Timestamp;
  endAt: firestore.Timestamp;
}
