<section class="main min-box">
  <div class="title-block cf mt45">
    <h1>
      <img loading="lazy" src="assets/img/not_found.png" alt="NOT FOUND" />
    </h1>
  </div>
  <h2 class="txt-center">ページは見つかりません</h2>
  <p class="txt-center mt10">
    お探しのページは見つかりませんでした。<br />QRコードの再読込みをお試しください。
  </p>
</section>
