// アンケート設問一覧
export const questionnaires: { question: string; answers: string[] }[] = [
  {
    question: 'させぼ五番街 11周年アニバーサリーをどこで知りましたか？',
    answers: [
      'TVCM',
      'HP',
      'SNS',
      '五番街に来て',
      '知人・ご家族から',
      'ショップ店員から',
    ],
  },
  {
    question: 'させぼ五番街への来館頻度についてお聞かせください。',
    answers: [
      '1週間に複数回',
      '1週間に1回程度',
      '月1回程度',
      '半年に1回程度',
      '年に1回程度',
      '初めて',
    ],
  },
  {
    question: '本日のご利用店舗数をお教えください。',
    answers: ['1店舗', '2店舗', '3店舗', '4～5店舗', '6～9店舗', '10店舗以上'],
  },
  // {
  //   question: '各ショップの11周年特別クーポンはご利用されましたか？',
  //   answers: ['YES', 'NO'],
  // },
];
